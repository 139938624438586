/* eslint-disable no-underscore-dangle */
import { Spinner } from "@adasupport/byron";
import type * as Immutable from "immutable";
import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import { useDispatch, useSelector } from "react-redux";

import { getTags } from "actions";
import { fetchAllResponsesShallow } from "actions/responses";
import { ControlledAccessButton as Button } from "components/Common/Button/ControlledAccessButton";
import { InputSearch } from "components/Common/InputSearch";
import {
  ReadOnlyWrapper,
  withReadOnlyWrapper,
} from "components/Common/ReadOnlyWrapper";
import SvgIcon from "components/Common/SvgIcon";
import { useLogSearchRelevancy } from "components/Declarative/Pages/Folders/hooks/useLogSearchRelevancy";
import { versionedResponsesSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";
import { type Modality } from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { type ResponseRecord } from "reducers/responses/types";
import { getHeight, getListHeight, shouldOpenUp } from "services/dropdown";
import { NO_OP_FUNCTION } from "services/helpers";
import { selectModality } from "services/responses/selectors/selectModality";
import colors from "stylesheets/utilities/colors.scss";

import { ResponseListItem } from "./ResponseListItem";
import {
  ResponseSearchResult,
  type ResponseSearchResultItem,
} from "./ResponseListItem/ResponseSearchResult";
import { MAX_RESPONSES_TO_SHOW, useCancellableSearch } from "./helpers";
import * as S from "./styles";

interface Props {
  selectedResponseId?: string | null;
  onChange: (newResponseId: string | null) => void;
  responseIdsToExclude?: string[] | Immutable.List<string>;
  isInvalid?: boolean;
  disabled?: boolean;
  predictions?: string[];
  quickReplyBlockButton?: boolean;
  hasCreateNewAnswerButton?: boolean;
  onCreateNewAnswer?: ((newResponseName: string) => void) | null;
  defaultOpen?: boolean;
  hideDropdownSelector?: boolean;
}

export const ResponseSelector = ({
  selectedResponseId,
  onChange,
  responseIdsToExclude = [],
  isInvalid = false,
  disabled = false,
  predictions = [],
  quickReplyBlockButton = false,
  hasCreateNewAnswerButton = false,
  onCreateNewAnswer = null,
  defaultOpen = false,
  hideDropdownSelector = false,
}: Props) => {
  const dispatch = useDispatch();

  const modality = useSelector(selectModality);

  const tags = useSelector((state) => state.tags);

  const responses: Immutable.List<ResponseRecord> = useSelector(
    versionedResponsesSelector,
  );

  useComponentDidMount(() => {
    if (tags.length === 0) {
      dispatch(getTags());
    }

    if (responses.size === 0) {
      dispatch(fetchAllResponsesShallow());
    }
  });

  const selectedResponse = responses.find(
    (r: ResponseRecord) => r.id === selectedResponseId,
  );

  const { searchQuery, setSearchQuery, searchResults, isLoading } =
    useCancellableSearch();

  const triggerRef = useRef<HTMLDivElement>(null);

  const [isListOpen, setIsListOpen] = useState(defaultOpen);
  const listRef = useOnClickOutside(() => {
    setIsListOpen(false);
  });
  const { logResponseSearchRelevancy } = useLogSearchRelevancy();

  /**
   * If user tries to select/copy/paste a block containing
   * a reference to another response with a incompatible modality, we remove it.
   */
  useEffect(() => {
    if (selectedResponse && modality) {
      const { reservedFor } = selectedResponse;
      const isModalityIncompatible = reservedFor
        ? reservedFor !== modality
        : false;

      if (isModalityIncompatible) {
        onChange(null);
      }
    }
  }, [onChange, modality, selectedResponse]);

  function closeList() {
    setIsListOpen(false);
    setSearchQuery("");
  }

  /**
   * Defines if a response can be listed on the selector,
   * based upon a couple of defined rules.
   */
  const canListResponse = (
    responseId: string | null,
    reservedFor: Modality | null,
  ) => {
    if (!responseId) return false;

    const hasIdsToExclude = responseIdsToExclude.includes(responseId);
    const isSelectedResponse = responseId === selectedResponseId;

    const shouldCheckModality = Boolean(reservedFor && modality);
    const isModalityIncompatible = shouldCheckModality
      ? reservedFor !== modality
      : false;

    return !hasIdsToExclude && !isSelectedResponse && !isModalityIncompatible;
  };

  /**
   * Filter shallow responses to display as soon as user opens the dropdown.
   */
  const filterResponses = (responseList: Immutable.List<ResponseRecord>) =>
    responseList.filter((responseItem) =>
      canListResponse(responseItem.id, responseItem.reservedFor),
    );

  /**
   * Filter searched responses to display as soon as user opens the dropdown.
   */
  const filterResponsesForSearchResult = (
    results: ResponseSearchResultItem[] | null,
  ) =>
    results?.filter((resultItem) =>
      canListResponse(resultItem._id, resultItem._source.reserved_for),
    );

  const filteredSearchResults: ResponseSearchResultItem[] | undefined =
    filterResponsesForSearchResult(searchResults);

  function renderResponseSearchResults() {
    if (!filteredSearchResults || filteredSearchResults.length === 0) {
      return <S.ExactlyCentered>No matching Answers found</S.ExactlyCentered>;
    }

    const handleClick = (
      responseId: string,
      result: ResponseSearchResultItem,
      index: number,
    ) => {
      logResponseSearchRelevancy({
        result,
        optionNumber: index + 1,
        numberSearchResults: filteredSearchResults.length,
        searchQuery,
      });

      closeList();
      onChange(responseId);
    };

    const trimmedSearchResults = filteredSearchResults.slice(
      0,
      MAX_RESPONSES_TO_SHOW,
    );

    return (
      <>
        {trimmedSearchResults.map((result: ResponseSearchResultItem, index) => (
          <ResponseSearchResult
            key={index}
            responseSearchResult={result}
            onClick={(responseId: string) =>
              handleClick(responseId, result, index)
            }
          />
        ))}
        {filteredSearchResults.length > MAX_RESPONSES_TO_SHOW && (
          <S.MaxAnswersMessage>
            Only {MAX_RESPONSES_TO_SHOW} answers are shown here. Enter a more
            specific search query to see other answers.
          </S.MaxAnswersMessage>
        )}
      </>
    );
  }

  function renderResponses(responsesToRender: Immutable.List<ResponseRecord>) {
    const trimmedResponses = responsesToRender.slice(0, MAX_RESPONSES_TO_SHOW);

    return (
      <>
        {trimmedResponses.map((response) => (
          <ResponseListItem
            key={response.id}
            onClick={(responseId: string) => {
              closeList();
              onChange(responseId);
            }}
            response={response}
          />
        ))}
        {responsesToRender.size > MAX_RESPONSES_TO_SHOW && (
          <S.MaxAnswersMessage>
            Only {MAX_RESPONSES_TO_SHOW} answers are shown here. Enter a search
            query to see other answers.
          </S.MaxAnswersMessage>
        )}
      </>
    );
  }

  // When training predictions are enabled, in the questions view, this divides the responses into
  // two groups: "suggested answers" and "all answers"
  function renderResponsesWithPredictions(
    responsesToRender: Immutable.List<ResponseRecord>,
  ) {
    const predictedResponses = responsesToRender.filter((r) =>
      predictions.includes(r.id),
    );
    const otherResponses = responsesToRender.filter(
      (r) => !predictions.includes(r.id),
    );

    return (
      <>
        <S.Heading>
          <S.HeadingIconContainer>
            <SvgIcon icon="Lightning" fillColor="currentColor" />
          </S.HeadingIconContainer>
          <S.HeadingText>Suggested Answers</S.HeadingText>
        </S.Heading>
        {renderResponses(predictedResponses)}
        <S.Heading>
          <S.HeadingIconContainer>
            <SvgIcon icon="Category" fillColor="currentColor" />
          </S.HeadingIconContainer>
          <S.HeadingText>All Answers</S.HeadingText>
        </S.Heading>
        {renderResponses(otherResponses)}
      </>
    );
  }

  const renderResponseList = () => {
    if (filteredSearchResults) {
      return renderResponseSearchResults();
    }

    const shouldSeparatePredictedResponses = Boolean(
      predictions.length && !searchQuery,
    );

    const responsesAfterExcluding = filterResponses(responses);

    if (shouldSeparatePredictedResponses) {
      return renderResponsesWithPredictions(responsesAfterExcluding);
    }

    return renderResponses(responsesAfterExcluding);
  };

  const renderDropdown = () => (
    <S.ListContainer
      ref={listRef}
      triggerHeight={getHeight(triggerRef.current)}
      shouldOpenUp={shouldOpenUp(triggerRef.current)}
      listHeight={getListHeight(triggerRef.current)}
    >
      <S.SearchContainer>
        <S.SearchInputContainer>
          <InputSearch
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchQuery(e.target.value)
            }
            placeholder="Search Answers"
            autoFocus
          />
        </S.SearchInputContainer>
        {hasCreateNewAnswerButton && (
          <S.NewResponseButtonContainer>
            <Button
              // This is confusing - we should probably just open the modal and create an
              // answer on the spot instead
              onClick={(e: React.MouseEvent) => {
                if (onCreateNewAnswer) {
                  onCreateNewAnswer(searchQuery);
                } else {
                  onChange("__new__");
                }

                setSearchQuery("New answer");
                setIsListOpen(false);
                e.stopPropagation(); // Or else it will propagate to trigger and re-open list
              }}
              title="Create a new answer"
              icon="Compose"
              iconLarge
            />
          </S.NewResponseButtonContainer>
        )}
      </S.SearchContainer>
      <S.ResponseListContainer>
        {isLoading ? (
          <S.ExactlyCentered>
            <Spinner />
          </S.ExactlyCentered>
        ) : (
          renderResponseList()
        )}
      </S.ResponseListContainer>
    </S.ListContainer>
  );

  /* Instead of showing the selected response, show a custom button in the quick replies block.
   * If necessary, with minimal effort we can generalize this with customizable prompt text to
   * allow adding responses to a list in any context.
   */
  if (quickReplyBlockButton) {
    return (
      <S.QuickReplyBlockButtonContainer
        ref={triggerRef}
        onClick={disabled ? NO_OP_FUNCTION : () => setIsListOpen(true)}
        disabled={disabled}
        active={isListOpen}
      >
        <SvgIcon icon="Add" height={24} />
        <S.QuickReplyBlockButtonText data-testid="qr-response-selector-trigger">
          Add Quick Reply
        </S.QuickReplyBlockButtonText>
        <SvgIcon icon="ChevronDown" height={20} />
        {isListOpen && renderDropdown()}
      </S.QuickReplyBlockButtonContainer>
    );
  }

  const placeholder =
    searchQuery ||
    (hasCreateNewAnswerButton
      ? "Choose or Create an Answer"
      : "Choose an Answer");

  return (
    <S.Trigger
      ref={triggerRef}
      isOpen={isListOpen}
      onClick={disabled ? NO_OP_FUNCTION : () => setIsListOpen(true)}
      isInvalid={isInvalid}
      disabled={disabled}
      data-testid="response-selector-trigger"
    >
      {!hideDropdownSelector && (
        <S.TriggerContent>
          <S.ResponseTitleText>
            {selectedResponse?.handle || placeholder}
          </S.ResponseTitleText>
          <S.TriggerButtonsContainer>
            {selectedResponseId && (
              <S.DeleteButtonContainer>
                <ReadOnlyWrapper hide>
                  <Button
                    onClick={() => onChange(null)}
                    title="Clear selected Answer"
                    icon="CircleRemove"
                    fillColor={colors.colorUIBad}
                    clear
                    tabIndex={-1}
                  />
                </ReadOnlyWrapper>
              </S.DeleteButtonContainer>
            )}
            <S.DropdownIconContainer>
              <SvgIcon icon="ChevronDown" height={24} />
            </S.DropdownIconContainer>
          </S.TriggerButtonsContainer>
        </S.TriggerContent>
      )}
      {isListOpen && renderDropdown()}
    </S.Trigger>
  );
};

export default withReadOnlyWrapper(ResponseSelector);
