import classNames from "classnames";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/Common/Button";
import "./style.scss";

export const TODAY = moment().format("YYYY-MM-DD");

const BLOCK_NAME = "DatePicker";
const DAY_NAMES = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export class DatePicker extends React.Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.renderDates = this.renderDates.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleCycleMonthPrev = this.handleCycleMonth.bind(this, "prev");
    this.handleCycleMonthNext = this.handleCycleMonth.bind(this, "next");

    this.state = {
      // this field is used to store the year and month for the current page
      componentTime: props.selectedDay || TODAY,
    };
  }

  static propTypes = {
    // this prop states if it is the start or end of a date range
    activeInput: PropTypes.string.isRequired,
    // this prop takes the selected date in the calendar
    selectedDay: PropTypes.string,
    // this prop takes the first day of the date range
    firstDay: PropTypes.string,
    // this prop takes the last day of the date range
    lastDay: PropTypes.string,
    // this prop takes the minimum date of the calendar
    minDate: PropTypes.string,
    // this prop takes the maximum date of the calendar
    maxDate: PropTypes.string,
    // this prop states wether this component will contain a date range
    hasRange: PropTypes.bool,
    // this prop takes in an onChange function
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedDay: TODAY,
    firstDay: TODAY,
    lastDay: TODAY,
    minDate: null,
    maxDate: null,
    hasRange: true,
  };

  /**
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const { selectedDay } = this.props;

    if (selectedDay !== prevProps.selectedDay) {
      this.setState({
        componentTime: selectedDay,
      });
    }
  }

  /**
   * @returns {Boolean}
   */
  get isNextMonthDisabled() {
    const { componentTime } = this.state;
    const { maxDate } = this.props;

    if (!maxDate) {
      return false;
    }

    // is the max date before the selected date?
    const selectedDateMoment = moment.utc(componentTime);

    return !selectedDateMoment.isBefore(moment.utc(maxDate), "month");
  }

  /**
   * @returns {Boolean}
   */
  get isPrevMonthDisabled() {
    const { componentTime } = this.state;
    const { minDate } = this.props;

    if (!minDate) {
      return false;
    }

    // is the min date after the selected date?
    const selectedDateMoment = moment.utc(componentTime);

    return !selectedDateMoment.isAfter(moment.utc(minDate), "month");
  }

  /**
   * Callback onChange, after convert date to ISO String
   *
   * @param {Object} startDate
   * @param {Object} endDate
   */
  handleOnChange(startDate, endDate) {
    const { onChange } = this.props;

    onChange(
      startDate ? startDate.toISOString() : null,
      endDate ? endDate.toISOString() : null,
    );
  }

  /**
   * Cycling month on the title on arrow click
   *
   * @param {String} direction
   */
  handleCycleMonth(direction) {
    const { componentTime } = this.state;
    const componentTimeMoment = moment.utc(componentTime);
    const updatedMonth =
      direction === "next"
        ? componentTimeMoment.add(1, "months")
        : componentTimeMoment.subtract(1, "months");

    this.setState({
      componentTime: updatedMonth.toISOString(),
    });
  }

  /**
   * Set date range to the current month on calendar title click
   *
   * @param {*} timeRange
   */
  handleCalendarClick(timeRange) {
    const { componentTime } = this.state;
    const today = moment.utc(TODAY);
    const startDate = moment.utc(componentTime).startOf(timeRange);

    // End day will be today only when the current page shows the current month
    const componentTimeMoment = moment.utc(componentTime);
    const endDate =
      componentTimeMoment.month() < today.month() ||
      componentTimeMoment.year() < today.year()
        ? componentTimeMoment.endOf(timeRange)
        : today;

    this.handleOnChange(startDate, endDate);
  }

  /**
   * Leave empty for the day at start of month before the first day of the month
   *
   * @param {Number} firstDayOfMonthOffset
   * @returns {Array.<ReactElement>}
   */
  static renderEmptyDatesStart(firstDayOfMonthOffset) {
    return [...Array(firstDayOfMonthOffset)].map((o, i) => (
      <div
        className={`${BLOCK_NAME}__dates-date
         ${BLOCK_NAME}__dates-date-placeholder`}
        key={i}
      />
    ));
  }

  /**
   * Leave empty at the end of each month after the last day
   *
   * @param {Number} firstDayOfMonthOffset
   * @param {Number} daysInMonth
   * @returns {Array.<ReactElement>}
   */
  static renderEmptyDatesEnd(firstDayOfMonthOffset, daysInMonth) {
    return [
      ...Array((7 - ((firstDayOfMonthOffset + daysInMonth) % 7)) % 7),
    ].map((o, i) => (
      <div
        className={`${BLOCK_NAME}__dates-date
         ${BLOCK_NAME}__dates-date-placeholder`}
        key={i}
      />
    ));
  }

  /**
   * Render all date buttons
   *
   * @param {Number} daysInMonth
   * @returns {Array.<ReactElement>}
   */
  renderDates(daysInMonth) {
    const { componentTime } = this.state;

    const {
      activeInput,
      selectedDay,
      minDate,
      maxDate,
      hasRange,
      firstDay,
      lastDay,
    } = this.props;

    const selectedDayMoment = selectedDay ? moment.utc(selectedDay) : null;

    return [...Array(daysInMonth)].map((o, i) => {
      const currentDate = moment
        .utc(componentTime)
        .date(i + 1)
        .startOf("day");
      const isActive = currentDate.isSame(selectedDayMoment, "day");

      let isBetweenRange = false;
      let selectedDayFirst = null;
      let selectedDayLast = null;

      const isDisabled =
        (maxDate && moment.utc(maxDate).isBefore(currentDate)) ||
        (minDate && moment.utc(minDate).isAfter(currentDate));

      if (hasRange) {
        const momentFirstDay = moment.utc(firstDay);
        const momentLastDay = moment.utc(lastDay);
        isBetweenRange =
          currentDate.isSameOrAfter(momentFirstDay) &&
          currentDate.isSameOrBefore(momentLastDay);

        if (activeInput === "start") {
          selectedDayFirst = currentDate;
        } else {
          selectedDayLast = currentDate;
        }
      } else {
        selectedDayFirst = currentDate;
      }

      const hasHover = hasRange && !isDisabled && isBetweenRange;

      return (
        <button
          type="button"
          className={classNames(`${BLOCK_NAME}__dates-date`, {
            [`${BLOCK_NAME}__dates-date--disabled`]: isDisabled,
            [`${BLOCK_NAME}__dates-date--active`]: !isDisabled && isActive,
            [`${BLOCK_NAME}__dates-date--hover-active`]: hasHover,
          })}
          onClick={
            isDisabled
              ? null
              : () => this.handleOnChange(selectedDayFirst, selectedDayLast)
          }
          key={currentDate.date()}
        >
          {i + 1}
        </button>
      );
    });
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const { componentTime } = this.state;

    const componentTimeMoment = moment.utc(componentTime);

    const daysInMonth = componentTimeMoment.daysInMonth(); // how many days in this month, integer
    const firstDayOfMonthOffset = componentTimeMoment.date(1).day(); // one of Mon ~ Sun for the first day of this month, in number (1~7)

    // rendering headers for day of week
    const dayNameElements = DAY_NAMES.map((name) => (
      <div className={`${BLOCK_NAME}__dates-names-name`} key={name}>
        {name}
      </div>
    ));

    return (
      <div className={BLOCK_NAME}>
        <div className={`${BLOCK_NAME}__selectors`}>
          <Button
            onClick={this.handleCycleMonthPrev}
            icon="ArrowLeft"
            customClassName={`${BLOCK_NAME}__selectors-button`}
            light
            disabled={this.isPrevMonthDisabled}
          />
          <div className={`${BLOCK_NAME}__selectors-dates`}>
            <div className={`${BLOCK_NAME}__selectors-dates-month`}>
              <button
                type="button"
                className={`${BLOCK_NAME}__selectors-dates-month-title`}
                onClick={() => this.handleCalendarClick("month")}
              >
                {componentTimeMoment.format("MMMM YYYY")}
              </button>
            </div>
          </div>
          <Button
            onClick={this.handleCycleMonthNext}
            icon="ArrowRight"
            customClassName={`${BLOCK_NAME}__selectors-button`}
            light
            disabled={this.isNextMonthDisabled}
          />
        </div>
        <div className={`${BLOCK_NAME}__dates`}>
          <div className={`${BLOCK_NAME}__dates-names`}>{dayNameElements}</div>
          {DatePicker.renderEmptyDatesStart(firstDayOfMonthOffset)}
          {this.renderDates(daysInMonth)}
          {DatePicker.renderEmptyDatesEnd(firstDayOfMonthOffset, daysInMonth)}
        </div>
      </div>
    );
  }
}
