import classNames from "classnames";
import React, { type ReactElement, type ReactNode } from "react";

import SvgIcon from "components/Common/SvgIcon";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";

function renderStepNumber(number: number): ReactElement {
  return (
    <div className="NumberedSteps__step-number">
      <span className="NumberedSteps__step-number__number">{number}</span>
    </div>
  );
}

interface NumberedStepsProps {
  children: () => ReactNode[];
  currentStep: number;
}

export function NumberedSteps(props: NumberedStepsProps): ReactElement {
  const { children, currentStep } = props;

  return (
    <div className="NumberedSteps">
      {children().map((block, index) => {
        const stepNumber = index;
        const stepCompleted = stepNumber < currentStep;

        return (
          <div
            key={index}
            className={classNames("NumberedSteps__step", {
              "NumberedSteps__step--complete": stepCompleted,
              "NumberedSteps__step--active": stepNumber === currentStep,
            })}
          >
            {stepCompleted ? (
              <SvgIcon
                icon="CircleCheckmark"
                customClassName="NumberedSteps__checkmark"
                fillColor={colors.colorUIGood}
              />
            ) : (
              renderStepNumber(stepNumber + 1)
            )}
            <div className="NumberedSteps__block">{block}</div>
          </div>
        );
      })}
    </div>
  );
}
