import React, { type ErrorInfo } from "react";

import SvgIcon from "components/Common/SvgIcon";
import { trackReactComponentError } from "services/errorTracker";

import "./style.scss";

interface ErrorPageProps {
  browserError?: boolean;
}

class ErrorPage extends React.Component<ErrorPageProps> {
  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: ErrorInfo) {
    trackReactComponentError(error, info);
  }

  renderText() {
    const { browserError = false } = this.props;

    if (browserError) {
      return (
        <div className="ErrorPage__text">
          <h1 className="ErrorPage__text__title">Unsupported Browser</h1>
          <h5 className="ErrorPage__text__description">
            Please use{" "}
            <a
              href="https://www.google.ca/chrome/browser/desktop/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome
            </a>{" "}
            to have the best experience managing your bot
          </h5>
        </div>
      );
    }

    return (
      <div className="ErrorPage__text">
        <h1 className="ErrorPage__text__title">
          It&apos;s not you, it&apos;s us
        </h1>
        <h5 className="ErrorPage__text__description">
          Our services are temporarily unavailable. <br />
          Stay on this page and we will automatically re-direct you when things
          are back up.
        </h5>
      </div>
    );
  }

  render() {
    return (
      <div className="ErrorPage">
        <SvgIcon icon="Ada" customClassName="ErrorPage__icon" />
        {this.renderText()}
      </div>
    );
  }
}

export default ErrorPage;
