import styled from "styled-components";

import { c, v } from "constants/css";

export const Root = styled.div<{
  hasGreyBackground?: boolean;
  overflowVisible?: boolean;
}>`
  width: 100%;
  display: flex;
  border-radius: 10px;
  ${({ overflowVisible }) =>
    !overflowVisible &&
    `
      overflow-x: hidden;
  `};
  ${({ hasGreyBackground }) =>
    hasGreyBackground &&
    `
      background-color: ${c.colorGreyBg};
  `};
`;

export const Content = styled.div<{
  noPadding?: boolean;
  flexible?: boolean;
  isNarrow?: boolean;
  fullWidth?: boolean;
}>`
  flex: 1 1 0;
  margin: 0 auto;
  padding: ${v.gUnitQuadruple} 0;
  max-width: ${v.midContainerWidth};

  ${({ isNarrow }) => `
    @media (min-width: ${v.windowBreakpoint}) {
      ${
        isNarrow
          ? `
        max-width: ${v.midContainerWidth};
    `
          : `
        max-width: ${v.largeContainerWidth};
    `
      }
    }
  
    @media (max-width: ${v.windowBreakpoint}) {
      ${
        isNarrow
          ? `
        max-width: ${v.narrowContainerWidth};
    `
          : `
        max-width: ${v.midContainerWidth};
    `
      }
    }
  `}

  ${({ flexible }) =>
    flexible &&
    `
    max-width: v.largeContainerWidth;
  `}
  
  ${({ noPadding }) =>
    noPadding
      ? `
    padding-top: 0;
    padding-bottom: 0;
  `
      : ""}

    ${({ fullWidth }) =>
    fullWidth &&
    `
      max-width: 100% !important;
    `}
  

  @media print {
    max-width: 100% !important;
    padding: ${v.gUnit};
  }
`;

export const SideSpace = styled.div`
  flex: 0 0 auto;
  min-width: 50px;
  max-width: 54px;
  width: 5%;
`;
