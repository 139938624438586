/** @deprecated Use CsatType instead */
export enum CsatSurveyType {
  ANSWER_FLOW = "answer_flow",
  END_CHAT_CSAT = "end_chat",
  PROACTIVE_CSAT = "proactive",
  AGENT_LIVE_CHAT_CSAT = "live_chat",
}

export type CsatType = "answer_flow" | "end_chat" | "proactive" | "live_chat";

export interface CsatResultAttributes {
  style: CsatStyle;
  score: number;
  originalScore?: string | null;
  surveyType: CsatType | null;
  feedback?: string[];
  isPositive?: boolean;
  resolved?: boolean | null;
  comment?: string;
}

/** @deprecated Use CsatStyle instead */
export enum CSATSatisfactionStyle {
  THUMBS = "THUMBS",
  NUMERIC = "NUMERIC",
  NUMERIC_TEN = "NUMERIC_TEN",
  EMOJI = "EMOJI",
}

export type CsatStyle = "THUMBS" | "NUMERIC" | "NUMERIC_TEN" | "EMOJI";
