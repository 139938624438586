import React, { Component } from "react";

import * as S from "components/Common/GlobalErrorBoundary/styles";
import { type GlobalErrorBoundaryState } from "components/Common/GlobalErrorBoundary/types";
import ErrorPage from "components/Declarative/Pages/ErrorPage";
import { trackReactComponentError } from "services/errorTracker";

interface Props {
  children?: React.ReactNode;
}
export class GlobalErrorBoundary extends Component {
  state: GlobalErrorBoundaryState = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    trackReactComponentError(error, info);
  }

  render() {
    const { hasError, error } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props as Props;

    if (process.env.NODE_ENV === "development" && hasError && error) {
      return (
        <S.ErrorContainer>
          <S.ErrorTitle>Something went wrong:</S.ErrorTitle>
          <S.ErrorMessage>{error.stack}</S.ErrorMessage>
        </S.ErrorContainer>
      );
    }

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}
