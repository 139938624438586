import { createSlice } from "@reduxjs/toolkit";

import { type PlaybooksState } from "./types";

const initialState: PlaybooksState = {
  toastNotification: null,
};

const playbooks = createSlice({
  name: "playbooks",
  initialState,
  reducers: {
    setToastNotification(state, action) {
      return { ...state, toastNotification: action.payload };
    },
    clearToastNotification(state) {
      return { ...state, toastNotification: null };
    },
  },
});

export const { setToastNotification, clearToastNotification } =
  playbooks.actions;
export const { reducer: playbooksReducer } = playbooks;
