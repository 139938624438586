import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";
import { CHARACTER_LIMIT } from "constants/messages";
import { TAG_REGEX } from "constants/tags";
import { removeTemplateVariables } from "services/strings";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

function validTags(string: string): boolean {
  const variableRegex = /{([\w]+)\|([^}]*?)}/g;
  const emptyString = "";

  return (
    string
      .replace(variableRegex, emptyString)
      .replace(TAG_REGEX, emptyString)
      .replace(/ /g, emptyString) !== emptyString
  );
}

interface ZendeskSupportHandoffAttributes extends BaseMessageRecordAttributes {
  type: "zendesk_support_handoff";
  isLiveAgentHandoff: true;
  fallbackResponseId: string | null;
  standbyText: string;
  customFields: Immutable.List<
    TypedMap<{ field_id: string; value: string; field_type?: string }>
  >;
  groupId: string | null;
  tags: string;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class ZendeskSupportHandoffMessageRecord extends BaseMessageRecord<ZendeskSupportHandoffAttributes>(
  {
    type: "zendesk_support_handoff",
    isLiveAgentHandoff: true,
    fallbackResponseId: null,
    standbyText:
      "An agent will be with you as soon as they can. You'll be notified here when the agent responds.",
    customFields: Immutable.List(),
    groupId: null,
    tags: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  },
) {
  static getInvalidFields: InvalidFieldsGetter<ZendeskSupportHandoffMessageRecord> =
    (message) => {
      const invalidFields = new Set<string>();

      const bodyWithoutTemplateVars = removeTemplateVariables(
        message.standbyText,
      );

      if (
        message.standbyText.trim().length <= 0 ||
        bodyWithoutTemplateVars.trim().length > CHARACTER_LIMIT
      ) {
        invalidFields.add("standbyText");
      }

      if (!message.standbyText) {
        invalidFields.add("standbyText");
      }

      if (!message.fallbackResponseId) {
        invalidFields.add("fallbackResponseId");
      }

      if (!message.groupId) {
        invalidFields.add("groupId");
      }

      if (message.tags && validTags(message.tags)) {
        invalidFields.add("tags");
      }

      return Immutable.List(invalidFields);
    };
}
