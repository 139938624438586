import {
  Button,
  Icon,
  IconButton,
  primitives,
  tokens,
} from "@adasupport/byron";
import React, { type ReactNode, useState } from "react";
import { useBetween } from "use-between";

import { openModalAction } from "actions/modal";
import { type ModalProps as LegacyModalProps } from "actions/modal/types";
import { type ThunkAction } from "actions/types";
import {
  Flex,
  FlexChild,
  HSpace,
  ModalContent,
  Text,
  VSpace,
} from "components/Common";

/** @deprecated */
export function openConfirmationModalAction(
  props: LegacyModalProps,
): ThunkAction {
  return (dispatch) => {
    dispatch(openModalAction("MODAL_WARNING", props));
  };
}

export { closeModalAction } from "actions/modal";

interface ModalProps {
  title: ReactNode;
  onClose?: () => void;
}

/** To be used with `useBetween` hook to persist values across application */
const useModalContent = () => {
  const [content, setContent] = useState<ReactNode>(null);
  const [props, setProps] = useState<ModalProps>({ title: "" });
  const [isShown, setIsShown] = useState<boolean>(false);

  return { content, setContent, props, setProps, isShown, setIsShown };
};

export const useModal = () => {
  const { content, setContent, props, setProps, isShown, setIsShown } =
    useBetween(useModalContent);

  return {
    modal: {
      props,
      isShown,
      content,
    },

    openModal: (modalProps: ModalProps, modalContent: ReactNode) => {
      setProps(modalProps);
      setContent(modalContent);
      setIsShown(true);
    },

    closeModal: () => {
      setIsShown(false);
    },
  };
};

const ConfirmationModal = ({ title, message, actions }: LegacyModalProps) => {
  const [pendingAction, setPendingAction] = useState<string | null>(null);

  return (
    <>
      <ModalContent width={480}>
        <Flex>
          <FlexChild>
            <div
              style={{
                background: primitives.palette.red100,
                display: "inline-flex",
                width: primitives.spacing.space9,
                height: primitives.spacing.space9,
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                transform: "translateY(-15%)",
              }}
            >
              <Icon.Exclamation
                style={{ transform: "translateY(-5%)" }}
                width={24}
                color={tokens.colors.text.negative}
              />
            </div>
            <HSpace size="space4" />
          </FlexChild>
          <FlexChild grow={1} shrink={1}>
            <Text block size="large" weight="semibold">
              {title}
            </Text>
            <VSpace size="space2" />
            <Text block size="small">
              {message}
            </Text>
          </FlexChild>
        </Flex>
      </ModalContent>

      <VSpace size="space2" />
      <Flex>
        <HSpace size="space4" />
        <Flex stretch gap justifyContent="flex-end">
          {actions?.map((action) => (
            <Button
              isLoading={pendingAction === action.title}
              key={action.title}
              onClick={async () => {
                setPendingAction(action.title);
                await action.onClick();
                setPendingAction(null);
              }}
              variant={(() => {
                switch (action.buttonTint) {
                  case "alert":
                    return "danger";
                  case "primary":
                    return "primary";
                  default:
                    return "tertiary";
                }
              })()}
              text={action.title}
            />
          ))}
        </Flex>
        <HSpace size="space4" />
      </Flex>
      <VSpace size="space4" />
    </>
  );
};

export const useConfirmationModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    openConfirmationModal: (props: LegacyModalProps) =>
      openModal({ title: null }, <ConfirmationModal {...props} />),
    closeModal,
  };
};

export const useVideoModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    openVideoModal: ({ videoUrl }: { videoUrl: string }) =>
      openModal(
        { title: null },
        <div
          style={{
            width: 800,
            height: 450,
            position: "relative",
            backgroundColor: primitives.palette.plum100,
            borderRadius: primitives.borderRadius.small,
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "100%",
              right: 0,
              marginBottom: primitives.spacing.space3,
            }}
          >
            <IconButton
              icon={Icon.Close}
              aria-label="Close"
              onClick={closeModal}
            />
          </div>
          <iframe
            title="video"
            src={videoUrl}
            width="100%"
            height="100%"
            style={{
              display: "block",
              border: "none",
              borderRadius: primitives.borderRadius.small,
            }}
          />
        </div>,
      ),
  };
};
