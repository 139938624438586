import { SelectPrimitive } from "@adasupport/byron";
import React from "react";

import { VariablePill } from "components/Common";
import {
  VARIABLE_TYPE_LABELS,
  type Variable,
  type VariableType,
  getVariableType,
  getVariableValueType,
} from "services/variables";

interface Props {
  errorMessage?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  value: string;
  variables: Variable[];
}

interface VariableGroup {
  type: VariableType;
  variables: Variable[];
}

export function LeftOperandVariableSelector({
  errorMessage,
  isDisabled,
  isInvalid,
  onChange,
  value,
  variables,
}: Props) {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const filterVariablesBySearchQuery = (variable: Variable) =>
    variable.name.toLowerCase().includes(searchQuery.toLowerCase());

  const variablesGroupedByType = variables
    .filter(filterVariablesBySearchQuery)
    .reduce((acc: VariableGroup[], variable) => {
      const type = getVariableType(variable);
      const typeGroup = acc.find((group) => group.type === type);

      if (typeGroup) {
        typeGroup.variables.push(variable);
      } else {
        acc.push({
          type,
          variables: [variable],
        });
      }

      return acc;
    }, []);

  const selectedVariable = variables.find((variable) => variable._id === value);

  return (
    <SelectPrimitive.Root>
      <SelectPrimitive.Trigger
        dataTestId="left-operand"
        errorMessage={errorMessage}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        placeholder="Choose a variable"
      >
        {selectedVariable && (
          <span title={selectedVariable.name}>
            <VariablePill
              name={selectedVariable.name}
              type={getVariableType(selectedVariable)}
              valueType={getVariableValueType(selectedVariable)}
            />
          </span>
        )}
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Dropdown>
        <SelectPrimitive.SearchBar onSearch={handleSearch} />
        <SelectPrimitive.Group>
          {variablesGroupedByType.map((group, index) => (
            <SelectPrimitive.Group
              key={index}
              label={VARIABLE_TYPE_LABELS[group.type]}
            >
              {group.variables
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((variable) => (
                  <SelectPrimitive.Item
                    key={variable._id}
                    value={variable._id}
                    isSelected={variable._id === value}
                    onSelect={onChange}
                  >
                    <VariablePill
                      name={variable.name}
                      type={getVariableType(variable)}
                      valueType={getVariableValueType(variable)}
                    />
                  </SelectPrimitive.Item>
                ))}
            </SelectPrimitive.Group>
          ))}
        </SelectPrimitive.Group>
      </SelectPrimitive.Dropdown>
    </SelectPrimitive.Root>
  );
}
