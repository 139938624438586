import { type LanguageCode, type TranslationObject } from "services/language";
import { type DeepReadonly } from "types";

import {
  type Client,
  type ClientFeatures,
  type PlatformType,
  type ScheduleDto,
} from "./types";

export type RawClient = DeepReadonly<{
  _id: string;
  agent_avatar: {
    default: boolean;
    image: string;
  };
  auto_translation: boolean;
  avatar: {
    use_letter: boolean;
    letter: string;
    image: string;
  };
  best_guesses: number;
  bot_persona: {
    personality: string[];
    emoji_usage: boolean;
    allowed_emojis: string;
    message_length: "concise" | "normal";
    multistep_instructions: "single" | "multiple";
    ask_for_more_help: boolean;
    no_customer_pi: boolean;
    no_competitors: boolean;
    competitor_names?: string;
    company_description?: string;
    default_timezone?: string;
    planner_prompt_override?: string;
    cr_prompt_override?: string;
  };
  bot_status?:
    | "demo"
    | "free_trial"
    | "internal"
    | "sandbox"
    | "production-onboarding"
    | "production-live";
  chat_button: {
    icon_type: "default" | "custom";
    icon_path: string | undefined;
    size: 44 | 56 | 64 | 72;
    background_color: string;
  };
  currency: string;
  did_complete_onboarding: boolean;
  enforce_mfa: { enforced: boolean };
  expression_filters: string[];
  features: ClientFeatures;
  handle: string;
  handoff?: {
    integrations?: { zendesk?: { enabled: boolean; [key: string]: unknown } };
    default_email?: string;
    reverse_transcript?: boolean;
  };
  language: LanguageCode;
  marketing_campaigns_order: string[];
  name: string;
  neg_response: boolean;
  num_predictive_suggestions: number;
  platforms?: Array<{ name: PlatformType; [key: string]: unknown }>;
  pos_response: boolean;
  privacy_settings: { ip_tracking: boolean };
  private_config: {
    goal_limit: number;
    max_enabled_web_actions: number;
    max_enabled_processes: number;
    max_enabled_handoffs: number;
    max_enabled_custom_instructions: number;
  };
  product_type: "generative" | "declarative";
  redactions: Array<{ active: boolean; key: string; regex: string }>;
  responses: { [key: string]: string };
  security_settings: {
    chat: { iframe_allow_list: string[] };
    app: { show_allow_list: boolean };
  };
  scheduled_block_schedules: ScheduleDto[] | null;
  article_suggestions_enabled: boolean;
  generative_replies_enabled: boolean;
  sso_dashboard_settings: { client_id: string; domain: string } | null;
  tint: string;
  token: string;
  translated_languages: LanguageCode[];
  trial_end_date: number | null;
  ui_settings: {
    chat: {
      advanced_colors_enabled: boolean;
      bot_description: TranslationObject;
      bot_description_enabled: boolean;
      bot_name: TranslationObject;
      branded_campaigns: "branded" | "unbranded";
      default_sound_on: boolean;
      download_transcript_toggle: boolean;
      email_transcript_setting: boolean;
      fallback_ui_settings: {
        default: boolean;
        icon: {
          default: boolean;
          image: string;
        };
        message: TranslationObject;
        links: Partial<Record<LanguageCode, { label: string; url: string }[]>>;
      };
      header_color: string | null;
      header_color_enabled: boolean;
      header_text_color: string;
      style: "round" | "rectangular";
      text_over_accent_color: string;
      text_size: "small" | "default" | "large";
      theme: "light" | "dark" | "auto";
    };
    embed: {
      button_text: TranslationObject | null;
      style: "round" | "text";
    };
    email?: {
      header_url: string;
      header_alignment: "left" | "center" | "right";
      sender_name: string;
      footer: Partial<Record<LanguageCode, string>>;
    };
  };
}>;

export function deserializeClient(rawClient: RawClient): Client {
  return {
    id: rawClient._id,
    agentAvatar: rawClient.agent_avatar,
    autoTranslation: rawClient.auto_translation,
    avatar: rawClient.avatar,
    bestGuesses: rawClient.best_guesses,
    botPersona: rawClient.bot_persona,
    botStatus: rawClient.bot_status,
    chatButton: rawClient.chat_button,
    currency: rawClient.currency,
    did_complete_onboarding: rawClient.did_complete_onboarding,
    enforceMfa: rawClient.enforce_mfa,
    expressionFilters: rawClient.expression_filters,
    features: rawClient.features,
    handle: rawClient.handle,
    handoff: rawClient.handoff,
    language: rawClient.language,
    marketingCampaignsOrder: rawClient.marketing_campaigns_order,
    name: rawClient.name,
    negResponse: rawClient.neg_response,
    numPredictiveSuggestions: rawClient.num_predictive_suggestions,
    platforms: rawClient.platforms,
    posResponse: rawClient.pos_response,
    privacySettings: rawClient.privacy_settings,
    privateConfig: rawClient.private_config,
    product_type: rawClient.product_type, // eslint-disable-line no-restricted-syntax
    redactions: rawClient.redactions,
    responses: rawClient.responses,
    securitySettings: rawClient.security_settings,
    scheduled_block_schedules: rawClient.scheduled_block_schedules,
    articleSuggestionsEnabled: rawClient.article_suggestions_enabled,
    generativeRepliesEnabled: rawClient.generative_replies_enabled,
    ssoDashboardSettings: rawClient.sso_dashboard_settings,
    tint: rawClient.tint,
    token: rawClient.token,
    translatedLanguages: rawClient.translated_languages,
    trialEndDate: rawClient.trial_end_date,
    uiSettings: rawClient.ui_settings,
  };
}

export function deserializePartialClient(
  rawClient: Partial<RawClient>,
): Partial<Client> {
  // Remove undefined values
  return Object.entries(deserializeClient(rawClient as RawClient)).reduce(
    (r, [key, value]) => (value === undefined ? r : { ...r, [key]: value }),
    {},
  );
}

export function serializeClient(client: Client): RawClient {
  return {
    _id: client.id,
    agent_avatar: client.agentAvatar,
    auto_translation: client.autoTranslation,
    avatar: client.avatar,
    best_guesses: client.bestGuesses,
    bot_persona: client.botPersona,
    bot_status: client.botStatus,
    chat_button: client.chatButton,
    currency: client.currency,
    did_complete_onboarding: client.did_complete_onboarding,
    enforce_mfa: client.enforceMfa,
    expression_filters: client.expressionFilters,
    features: client.features,
    handle: client.handle,
    handoff: client.handoff,
    language: client.language,
    marketing_campaigns_order: client.marketingCampaignsOrder,
    name: client.name,
    neg_response: client.negResponse,
    num_predictive_suggestions: client.numPredictiveSuggestions,
    platforms: client.platforms,
    pos_response: client.posResponse,
    privacy_settings: client.privacySettings,
    private_config: client.privateConfig,
    product_type: client.product_type, // eslint-disable-line no-restricted-syntax
    redactions: client.redactions,
    responses: client.responses,
    security_settings: client.securitySettings,
    scheduled_block_schedules: client.scheduled_block_schedules,
    article_suggestions_enabled: client.articleSuggestionsEnabled,
    generative_replies_enabled: client.generativeRepliesEnabled,
    sso_dashboard_settings: client.ssoDashboardSettings,
    tint: client.tint,
    token: client.token,
    translated_languages: client.translatedLanguages,
    trial_end_date: client.trialEndDate,
    ui_settings: client.uiSettings,
  };
}

export function serializePartialClient(
  client: Partial<Client>,
): Partial<RawClient> {
  // Remove undefined values
  return Object.entries(serializeClient(client as Client)).reduce(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (r, [key, value]) => (value === undefined ? r : { ...r, [key]: value }),
    {},
  );
}

export interface GetClientResponse {
  client: RawClient;
}

export function createClientFromResponse(response: GetClientResponse): Client {
  return deserializeClient(response.client);
}
