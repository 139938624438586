import { tokens } from "@adasupport/byron";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageSizeSelector = styled.div`
  font-size: ${tokens.font.size.px14};
  font-weight: ${tokens.font.weight.medium};
`;

export const Info = styled.span`
  font-size: ${tokens.font.size.px14};
`;

export const PageButtonContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-left: ${tokens.font.size.px16};
  column-gap: ${tokens.spacing.px8};
  justify-content: space-between;
`;
