import { Button } from "@adasupport/byron";
import { bindActionCreators } from "@reduxjs/toolkit";
import React, { type ReactElement } from "react";
import { connect } from "react-redux";

import { dismissAlert as dismissAlertAction } from "actions/alerts";
import { type Dispatch } from "actions/types";
import SvgIcon from "components/Common/SvgIcon";
import { ICON_MAP } from "constants/icons";
import { type AlertType } from "reducers/alerts/reducer";
import { type State } from "types";

import * as S from "./styles";

import "./style.scss";

interface PropsFromState {
  alert: AlertType;
}

interface PropsFromDispatch {
  dismissAlert(): void;
}

export type AlertsProps = PropsFromState & PropsFromDispatch;

function AlertsComponent(props: AlertsProps): ReactElement {
  const { alert, dismissAlert } = props;

  return (
    <div
      data-testid="alert"
      className={`Alerts ${alert.isVisible ? "" : "Alerts--hidden"}`}
    >
      <div
        className={`
                    Alerts__box
                    ${alert.isVisible ? "" : "Alerts__box--hidden"}
                `}
      >
        <div className="Alerts__box__icon-container">
          {alert.type && (
            <SvgIcon
              icon={ICON_MAP[alert.type].icon}
              fillColor={ICON_MAP[alert.type].color}
              customClassName="Alerts__box__icon-container__icon"
            />
          )}
        </div>
        <div className="Alerts__box__text">
          <div className="Alerts__box__text__message">{alert.message}</div>
          <S.AlertsButtonsContainer>
            {alert.buttons.map((b) => (
              <S.AlertsButtonWrapper key={b.label}>
                <Button
                  onClick={b.action}
                  text={b.label}
                  size="small"
                  variant="secondary"
                />
              </S.AlertsButtonWrapper>
            ))}
            <S.AlertsButtonWrapper>
              <Button
                onClick={dismissAlert}
                text="Dismiss"
                size="small"
                variant="secondary"
              />
            </S.AlertsButtonWrapper>
          </S.AlertsButtonsContainer>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state: State): PropsFromState {
  const { alert } = state;

  return { alert };
}

function mapDispatchToProps(dispatch: Dispatch): PropsFromDispatch {
  return {
    dismissAlert: bindActionCreators(dismissAlertAction, dispatch),
  };
}

export const Alerts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertsComponent);
