import { Button, Icon, Select, TextInput } from "@adasupport/byron";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { createUnsavedVariable } from "actions/variables";
import { IconWithLabel } from "components/Common/ByronSelectUtilities";
import {
  CaptureModeSelector,
  CaptureModeSelectorLabel,
} from "components/Common/CaptureModeSelector";
import {
  AUTO_CAPTURE_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
} from "constants/variables";
import { VariableRecord } from "reducers/variables/helpers";
import { useAlert } from "services/alert";
import { useClient } from "services/client";
import { useVariables } from "services/variables";
import { type CaptureMode } from "services/webActions";

import { Flex } from "../Flex";
import { InputHint } from "../InputHint";
import { InputLabel } from "../InputLabel";
import { VSpace4 } from "../Space";

const DATA_TYPE_OPTIONS = [
  {
    value: "string",
    label: <IconWithLabel text="String" icon={Icon.Text} />,
  },
  {
    value: "long",
    label: <IconWithLabel text="Number" icon={Icon.Number} />,
  },
  {
    value: "bool",
    label: <IconWithLabel text="Boolean" icon={Icon.IsFalse} />,
  },
] as const;

export const VariablePalette = ({
  onCreate = () => undefined,
  allowedScopes,
  defaultAttributes,
}: {
  onCreate?: (variableId: string) => void;
  allowedScopes?: (
    | typeof GLOBAL_VARIABLE_SCOPE
    | typeof AUTO_CAPTURE_VARIABLE_SCOPE
    | typeof SENSITIVE_VARIABLE_SCOPE
  )[];
  defaultAttributes?: Partial<{
    name: string;
    description: string;
    _type: "string" | "long" | "bool";
  }>;
}) => {
  const flags = useFlags();

  type VariableScope =
    | typeof GLOBAL_VARIABLE_SCOPE
    | typeof AUTO_CAPTURE_VARIABLE_SCOPE
    | typeof SENSITIVE_VARIABLE_SCOPE;

  const initialAttributes: {
    scope: VariableScope;
    name: string;
    description: string;
    always_ask: boolean;
    capture_modes: CaptureMode[];
    _type: "string" | "long" | "bool";
  } = {
    scope: allowedScopes?.includes(AUTO_CAPTURE_VARIABLE_SCOPE)
      ? AUTO_CAPTURE_VARIABLE_SCOPE
      : GLOBAL_VARIABLE_SCOPE,
    name: "",
    description: "",
    always_ask: false as boolean,
    capture_modes: ["conversation"] as CaptureMode[],
    _type: "string",
    ...defaultAttributes,
  };

  const { client } = useClient();
  const [attributes, setNewVariableAttributes] = useState(initialAttributes);

  const [isCreating, setIsCreating] = useState(false);
  const [didAttemptCreate, setDidAttemptCreate] = useState(false);
  const { createVariable } = useVariables();
  const dispatch = useDispatch();
  const { createAlert } = useAlert();
  const inputContainerRef = React.useRef<HTMLDivElement | null>(null);

  const invalidFields = ((fields) => {
    if (!attributes.name) {
      fields.add("name");
    }

    if (attributes.scope === "auto_capture" && !attributes.description) {
      fields.add("description");
    }

    return fields;
  })(new Set<"name" | "description">());

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.querySelector("input")?.focus();
    }
  }, []);

  return (
    <>
      <InputLabel label="Variable type" />
      <Select
        isDisabled={allowedScopes?.length === 1}
        options={(
          [
            { value: AUTO_CAPTURE_VARIABLE_SCOPE, label: "Autocapture" },
            { value: GLOBAL_VARIABLE_SCOPE, label: "Global" },
            { value: SENSITIVE_VARIABLE_SCOPE, label: "Sensitive" },
          ] as const
        ).filter((option) =>
          allowedScopes ? allowedScopes.includes(option.value) : true,
        )}
        value={attributes.scope}
        onChange={(v: VariableScope) =>
          setNewVariableAttributes({ ...attributes, scope: v })
        }
      />

      <VSpace4 />

      <InputLabel label="Name" />
      <div ref={inputContainerRef}>
        <TextInput
          placeholder="Enter variable name"
          value={attributes.name}
          onChange={(v) => setNewVariableAttributes({ ...attributes, name: v })}
          isInvalid={didAttemptCreate && invalidFields.has("name")}
        />
        {didAttemptCreate && invalidFields.has("name") && (
          <InputHint type="error">Required</InputHint>
        )}
      </div>

      {attributes.scope === "auto_capture" && (
        <>
          <VSpace4 />
          <InputLabel label="Description" />
          <TextInput
            placeholder="Enter description"
            value={attributes.description}
            onChange={(v) =>
              setNewVariableAttributes({ ...attributes, description: v })
            }
            isInvalid={didAttemptCreate && invalidFields.has("description")}
          />
          {didAttemptCreate && invalidFields.has("description") ? (
            <InputHint type="error">Required</InputHint>
          ) : (
            <InputHint>Tell AI agent what to capture</InputHint>
          )}
          {flags.train_autocapture_always_ask && (
            <>
              <VSpace4 />
              <InputLabel label="Capture behavior" />
              <Select
                options={[
                  { value: false, label: "Ask when needed" },
                  { value: true, label: "Always ask" },
                ]}
                value={attributes.always_ask}
                onChange={(v) =>
                  setNewVariableAttributes({ ...attributes, always_ask: v })
                }
              />
              <InputHint>
                {attributes.always_ask
                  ? "Your AI Agent will always ask the customer to provide a value. If used in a Process, the customer will be asked every time the Process is run."
                  : "Your AI Agent will ask the customer if no value currently exists, or if the AI Agent decides that the current value isn't relevant to the current conversation."}
              </InputHint>
            </>
          )}
          {flags.voice_auto_capture_variables &&
            client.features.experiment_voice && (
              <>
                <VSpace4 />
                <CaptureModeSelectorLabel />
                <CaptureModeSelector
                  selectedCaptureModes={attributes.capture_modes}
                  dataType="text" // For now, all auto-capture variables are strings
                  onChange={(v) =>
                    setNewVariableAttributes({
                      ...attributes,
                      capture_modes: v,
                    })
                  }
                  hideDTMF
                />
              </>
            )}
        </>
      )}

      {attributes.scope === "global" && (
        <>
          <VSpace4 />
          <InputLabel label="Data type" />
          <Select
            options={DATA_TYPE_OPTIONS}
            value={attributes._type}
            onChange={(v: "string" | "long" | "bool") =>
              setNewVariableAttributes({ ...attributes, _type: v })
            }
          />
        </>
      )}

      <VSpace4 />
      <Flex justifyContent="flex-end">
        <Button
          text="Create variable"
          isLoading={isCreating}
          isDisabled={didAttemptCreate && invalidFields.size > 0}
          onClick={async () => {
            setDidAttemptCreate(true);

            if (invalidFields.size > 0) {
              return;
            }

            setIsCreating(true);

            try {
              const result = await createVariable(
                attributes.scope === "auto_capture"
                  ? {
                      ...attributes,
                      _type: "string",
                      scope: "auto_capture",
                    }
                  : attributes,
              );

              if ("data" in result && "_id" in result.data) {
                createAlert({
                  alertType: "success",
                  message: "Variable created successfully",
                });

                // Update the legacy redux state so the variable can be accessed by other blocks right away
                const legacyVar = new VariableRecord({
                  id: result.data._id,
                  name: attributes.name,
                  scope: attributes.scope,
                });
                dispatch(
                  createUnsavedVariable({
                    variable: legacyVar,
                    responseId: null,
                  }),
                );

                onCreate(result.data._id);
              } else {
                throw new Error("Failed to create variable");
              }
            } catch {
              createAlert({
                alertType: "error",
                message: "Failed to create variable",
              });
            } finally {
              setIsCreating(false);
              setDidAttemptCreate(false);

              // Reset form
              setNewVariableAttributes(initialAttributes);
            }
          }}
        />
      </Flex>
    </>
  );
};
