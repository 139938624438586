import { type Action, combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { type History } from "history";
import type Immutable from "immutable";

import { modal } from "components/Common/Modal/reducer";
import { settingsSecurity } from "components/Common/ModalProfile/ModalProfileSecurity/reducer";
import { modalSessionTimeout } from "components/Common/ModalSessionTimeout/reducer";
import { login } from "components/Declarative/Pages/Login/reducer";
import { questionsPage } from "components/Declarative/Pages/Questions/reducer";
import { responseEditorTrainerPage } from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/reducer";
import {
  authenticationList,
  authenticationModal,
} from "components/Declarative/Pages/Settings/SettingsAuthentication/reducer";
import { conversationsList } from "components/Shared/Pages/Conversations/reducer";
import { responseVersions } from "components/Shared/Pages/Responses/ResponseVersions/reducers/versions";
import { responsesPage } from "components/Shared/Pages/Responses/reducer";
import { settingsScheduleModal } from "components/Shared/Pages/Settings/SettingsSchedules/SettingsSchedulesModal/reducer";
import { settingsScheduledBlockSchedules } from "components/Shared/Pages/Settings/SettingsSchedules/reducer";
import { builderABTests } from "features/ABTesting/reducer";
import { type GoalsState } from "reducers/goals/types";
import { type Platforms } from "reducers/platforms/types";
import { variableManager } from "reducers/variableManager/reducer";
import { variables } from "reducers/variables/reducer";
import { type VariableState } from "reducers/variables/types";
import { clientReducer } from "services/client";
import { flagsReducer } from "services/flags";
import { webActionsApi } from "services/webActions";
import { AdaApiSlice } from "slices";
import { AresReportApi } from "slices/ares/aresReportsApi";
import { ConversationsApi } from "slices/conversations/conversationsApi";
import { conversationsReducer } from "slices/conversations/conversationsSlice";
import { CSATApi } from "slices/csat/csatApi";
import { GeneratedTopicsApi } from "slices/generatedTopics/generatedTopicsApi";
import { GuidanceApi } from "slices/guidance/guidanceApi";
import { knowledgeReducer } from "slices/knowledge/knowledgeSlice";
import { OnboardingQuickQuestionsApi } from "slices/onboardingQuickQuestions/onboardingQuickQuestionsApi";
import { platformIntegrationsReducer } from "slices/platformIntegrations/platformIntegrations";
import { playbooksReducer } from "slices/playbooks/playbooks";
import { proactiveConversationsReducer } from "slices/proactiveConversations/proactiveConversations";
import { ReasoningLogApi } from "slices/reasoningLog/reasoningLogSlice";
import { ResponsesApi } from "slices/responses/responsesApi";
import { trainingReducer } from "slices/training/trainingSlice";

import { actionIntegrations } from "./actionIntegrations/reducer";
import { alert } from "./alerts/reducer";
import { appIntegrations } from "./appIntegrations/reducer";
import auths from "./auths/reducer";
import { businessEventState } from "./businessEvents/reducer";
import { campaigns } from "./campaigns/reducer";
import { clientApiKeys } from "./clientApiKeys";
import { conversationSummaries } from "./conversationSummaries/reducer";
import { conversationsTopics } from "./conversationsTopics/reducer";
import dragAndDrop from "./dragAndDrop";
import { error } from "./error/reducer";
import { expressionGroups } from "./expressionGroups/reducer";
import { expressions } from "./expressions/reducer";
import { foldersState } from "./folders/reducer";
import { goals } from "./goals/reducer";
import { handoffIntegrations } from "./handoffIntegrations/reducer";
import {
  nuanceLAAgents,
  nuanceLABusinessUnits,
  zawLAGroups,
  zendeskLADepartments,
} from "./liveAgent/reducer";
import { type LogRecord, logs } from "./logs/reducer";
import { messageBlocks } from "./messageBlocks/reducer";
import { modalOpenState } from "./modal/reducer";
import pages from "./pages";
import { platformsReducer } from "./platforms/reducer";
import { pushNotificationSettings } from "./pushNotificationSettings/reducer";
import resources from "./resources";
import { responses } from "./responses/reducer";
import { responsesLoaded } from "./responsesLoaded/reducer";
import { session } from "./session/reducer";
import { smartSearch } from "./smartsearch/reducer";
import { tags } from "./tags/reducer";
import { testVariablesModal } from "./testVariablesModal/reducer";
import { trainingSuggestionsState } from "./trainingSuggestions/reducer";
import { warnings } from "./warnings/reducer";
import { windowAlert } from "./windowAlert/reducer";

export const createRootReducer = (history: History) =>
  combineReducers({
    client: clientReducer,
    flags: flagsReducer,
    builderABTestsState: builderABTests,
    actionIntegrationsState: actionIntegrations,
    handoffIntegrationsState: handoffIntegrations,
    alert,
    appIntegrationsState: appIntegrations,
    auths,
    windowAlert,
    campaignsState: campaigns,
    conversationsSlice: conversationsReducer,
    conversationSummaries,
    conversationsTopicsState: conversationsTopics,
    businessEventState,
    conversationsList,
    error,
    expressions,
    expressionGroups,
    foldersState,
    goalsState: goals as () => GoalsState,
    questionsPage,
    login,
    /** @deprecated TODO: Remove unused reducer */
    logs: logs as () => Immutable.List<LogRecord>,
    messageBlocks,
    modal,
    modalSessionTimeout,
    platforms: platformsReducer as () => Platforms,
    responseEditorTrainerPage,
    pages,
    platformIntegrations: platformIntegrationsReducer,
    playbooks: playbooksReducer,
    proactiveConversations: proactiveConversationsReducer,
    pushNotificationSettingsState: pushNotificationSettings,
    resources,
    responses,
    responsesLoaded,
    responsesPage,
    router: connectRouter(history),
    session,
    settingsScheduledBlockSchedules,
    settingsScheduleModal,
    settingsSecurity,
    smartSearchState: smartSearch,
    tags,
    testVariablesModalState: testVariablesModal,
    knowledge: knowledgeReducer,
    training: trainingReducer,
    trainingSuggestionsState,
    variables: variables as () => VariableState,
    variableManager,
    zendeskLADepartments,
    zawLAGroups,
    nuanceLABusinessUnits,
    nuanceLAAgents,
    authenticationModal,
    authenticationList,
    modalOpenState,
    warnings,
    dragAndDrop,
    responseVersions,
    clientApiKeysState: clientApiKeys,
    [ConversationsApi.reducerPath]: ConversationsApi.reducer,
    [ResponsesApi.reducerPath]: ResponsesApi.reducer,
    [AdaApiSlice.reducerPath]: AdaApiSlice.reducer,
    [AresReportApi.reducerPath]: AresReportApi.reducer,
    [GeneratedTopicsApi.reducerPath]: GeneratedTopicsApi.reducer,
    [webActionsApi.reducerPath]: webActionsApi.reducer,
    [GuidanceApi.reducerPath]: GuidanceApi.reducer,
    [ReasoningLogApi.reducerPath]: ReasoningLogApi.reducer,
    [CSATApi.reducerPath]: CSATApi.reducer,
    [OnboardingQuickQuestionsApi.reducerPath]:
      OnboardingQuickQuestionsApi.reducer,
  });

export interface State
  extends ReturnType<ReturnType<typeof createRootReducer>> {}

const createAppReducer =
  (history: History) =>
  (state: Partial<State> = {}, action: Action) => {
    let newState: Partial<State> = state;

    // Clear store when unauthenticating
    // Client can be kept since it doesn't require authentication
    if (
      action.type === "UNAUTHENTICATE_SUCCESS" ||
      action.type === "UNAUTHENTICATE_FAILURE" ||
      action.type === "AUTO_LOGIN_FAILURE"
    ) {
      newState = {
        client: state.client,
        resources: {
          client: state.resources.client,
        },
      };
    }

    return createRootReducer(history)(newState as State, action);
  };

export default createAppReducer;
