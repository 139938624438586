import { tokens } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { colorPrimitives } from "constants/css";

export const AlertsButtonWrapper = styled.div`
  margin-right: ${tokens.spacing.px8};
`;

export const AlertsButtonsContainer = styled.div`
  display: flex;
`;

const visibleFragment = css`
  pointer-events: all;
  transform: translateY(0);
  opacity: 1;
`;

export const DarkAlertBox = styled.div<{ visible: boolean }>`
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #171a1e;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.12);
  border-radius: ${tokens.spacing.px8};
  padding: 18px ${tokens.spacing.px24};
  display: flex;
  align-items: center;
  color: ${colorPrimitives.white};
  transition: all 2000ms ease;
  opacity: 0;
  transform: translateY(120px);

  ${({ visible }) => visible && visibleFragment}

  > svg {
    margin-right: ${tokens.spacing.px8};
  }
`;

export const Message = styled.div`
  font-size: 14px;
  margin-right: ${tokens.spacing.px8};
`;

export const CloseDialogButton = styled.button`
  background: none;
  border: 0;
  color: ${colorPrimitives.white};
  cursor: pointer;
  height: ${tokens.spacing.px24};
  padding: 0;
`;

export const DialogActionButtonsContainer = styled.div`
  margin-right: ${tokens.spacing.px4};
`;

export const DialogActionButtons = styled.button`
  background: ${colorPrimitives.slate700};
  border-radius: ${tokens.spacing.px8};
  padding: ${tokens.spacing.px4} ${tokens.spacing.px16};
  color: ${colorPrimitives.white};
  border: 0;
  font-weight: ${tokens.font.weight.heavy};
  font-size: ${tokens.font.size.px14};
  line-height: ${tokens.font.lineHeight.lh100};
  cursor: pointer;
`;
