import { Icon, IconButton, Select } from "@adasupport/byron";
import React from "react";

import * as S from "./styles";

export interface PaginationProps {
  pageSize: number;
  setPageSize?: (val: number) => void;
  page: number;
  setPage: (val: number) => void;
  totalItems: number;
}

const pageSizeOptions = [
  {
    label: "25 Rows",
    value: 25,
  },
  {
    label: "50 Rows",
    value: 50,
  },
  {
    label: "75 Rows",
    value: 75,
  },
  {
    label: "100 Rows",
    value: 100,
  },
];

export function Pagination({
  pageSize,
  setPageSize,
  page,
  setPage,
  totalItems,
}: PaginationProps) {
  const maxPage = Math.ceil(totalItems / pageSize);
  const clampedPage = Math.min(Math.max(page, 1), maxPage);
  const pageStart = clampedPage === 1 ? 1 : (clampedPage - 1) * pageSize + 1;
  const pageEnd = Math.min(clampedPage * pageSize, totalItems);
  const nextDisabled = pageEnd >= totalItems;
  const prevDisabled = clampedPage === 1;

  return (
    <S.Container>
      {setPageSize ? (
        <S.PageSizeSelector>
          <Select
            options={pageSizeOptions}
            value={pageSize}
            onChange={setPageSize}
          />
        </S.PageSizeSelector>
      ) : (
        <div />
      )}
      <div>
        <S.Info>
          {pageStart}-{pageEnd} of {totalItems} results
        </S.Info>
        <S.PageButtonContainer>
          <IconButton
            aria-label="previous page"
            icon={Icon.ChevronLeft}
            onClick={() => setPage(clampedPage - 1)}
            variant="secondary"
            dataTestId="prev-button"
            isDisabled={prevDisabled}
          />
          <IconButton
            aria-label="next page"
            icon={Icon.ChevronRight}
            onClick={() => setPage(clampedPage + 1)}
            variant="secondary"
            dataTestId="next-button"
            isDisabled={nextDisabled}
          />
        </S.PageButtonContainer>
      </div>
    </S.Container>
  );
}
