import React, { type ReactNode, type Ref } from "react";

import * as S from "./styles";

export interface OwnProps {
  children: ReactNode;
  className?: string;
  flexible?: boolean;
  noPadding?: boolean;
  isNarrow?: boolean;
  id?: string;
  overflowVisible?: boolean;
  handleScroll?(e: React.UIEvent): void;
  hasGreyBackground?: boolean;
  fullWidth?: boolean;
}

export const Canvas = React.forwardRef(
  (props: OwnProps, ref: Ref<HTMLDivElement>) => {
    const {
      children,
      className,
      flexible,
      noPadding,
      isNarrow,
      handleScroll,
      hasGreyBackground,
      overflowVisible,
      fullWidth,
      id,
    } = props;

    return (
      <S.Root
        hasGreyBackground={hasGreyBackground}
        id={id}
        ref={ref}
        onScroll={handleScroll}
        overflowVisible={overflowVisible}
      >
        <S.SideSpace />
        <S.Content
          flexible={flexible}
          noPadding={noPadding}
          isNarrow={isNarrow}
          fullWidth={fullWidth}
          className={className}
        >
          {children}
        </S.Content>
        <S.SideSpace />
      </S.Root>
    );
  },
);

Canvas.defaultProps = {
  flexible: false,
  noPadding: false,
  isNarrow: false,
  hasGreyBackground: false,
  className: undefined,
  overflowVisible: false,
};

Canvas.displayName = "Canvas";
