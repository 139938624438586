import posthog, {
  type CaptureOptions,
  type CaptureResult,
  type Properties,
} from "posthog-js";

import { type User } from "resourceModels";
import { type Client } from "services/client";

export class UserAnalyticsService {
  private static isInitialized = false;

  static init(client: Client, user: User) {
    if (this.isInitialized) {
      return;
    }

    const { POSTHOG_PUBLIC_API_KEY, POSTHOG_API_URL, NODE_ENV } = window.__env;

    const isProduction = NODE_ENV === "production";

    if (isProduction && (!POSTHOG_PUBLIC_API_KEY || !POSTHOG_API_URL)) {
      if (!POSTHOG_PUBLIC_API_KEY) {
        console.error("POSTHOG_PUBLIC_API_KEY not set");
      }

      if (!POSTHOG_API_URL) {
        console.error("POSTHOG_API_URL not set");
      }

      return;
    }

    // We will use this to disable lots of tracking features for declarative bots,
    // as we the effort involved in identifying/redacting all PII in declarative
    // is too high for the value it would provide (which is very low)
    // eslint-disable-next-line no-restricted-syntax
    const isGenerative = client.product_type === "generative";

    posthog.init(POSTHOG_PUBLIC_API_KEY, {
      api_host: POSTHOG_API_URL,
      loaded: (posthogInstance) => {
        posthogInstance.identify(user.id, {
          client_handle: client.handle,
          client_id: client.id,
          bot_status: client.botStatus,
          user_id: user.id, // Do we need this here if it's already the distinct id?
          // eslint-disable-next-line no-restricted-syntax
          bot_sku: client.product_type,
          email: user.email,
          name: user.name,
        });

        posthogInstance.group("company", client.id, {
          name: client.name,
          client_handle: client.handle,
          bot_status: client.botStatus,
          // eslint-disable-next-line no-restricted-syntax
          bot_sku: client.product_type,
          language: client.language,
          trial_end_date: client.trialEndDate,
          currency: client.currency,
          bat_features: client.features,
        });
      },
      disable_session_recording:
        !isGenerative || !isProduction || user.email?.endsWith("@ada.support"),
      autocapture: isProduction,
      capture_pageview: isProduction,
      capture_pageleave: isProduction,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true, // Highly recommended as a minimum!!
          // color: false,
          // date: false,
          // 'datetime-local': false,
          email: false,
          // month: false,
          // number: false,
          // range: false,
          // search: false,
          tel: false,
          // text: false,
          // time: false,
          // url: false,
          // week: false,
          // textarea: false,
          // select: false,
        },
      },
    });
    this.isInitialized = true;
  }

  static identify(
    newDistinctId?: string,
    userPropertiesToSet?: Properties,
    userPropertiesToSetOnce?: Properties,
  ): void {
    posthog.identify(
      newDistinctId,
      userPropertiesToSet,
      userPropertiesToSetOnce,
    );
  }

  static reset() {
    posthog.reset();
  }

  static capture(
    eventName: string,
    properties?: Properties | null,
    options?: CaptureOptions,
  ): CaptureResult | void {
    try {
      posthog.capture(eventName, properties, options);

      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.debug(
          `%c[UserAnalyticsService] %c${eventName}`,
          "color: teal; font-weight: bold;",
          "color: orange; font-weight: bold;",
          {
            ...(properties && { properties }),
            ...(options && { options }),
          },
        );
      }
    } catch (error) {
      // silently fail
    }
  }
}
