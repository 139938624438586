/**
 * Test if a password contains at least one alphanumeric character
 */
export const validatePasswordAlphaNum = (password: string) =>
  new RegExp(/(.*[A-Z0-9])/, "g").test(password);

/**
 * Regex to test for if special character exists in password
 */
export const validatePasswordSpecialChar = (password: string) =>
  new RegExp(/(.*[^\w])/, "ig").test(password);

/**
 * Test if an input is a valid date
 */
export const validateDate = (dateString: string) =>
  new Date(dateString).toString() !== "Invalid Date";
