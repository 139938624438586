import { Button, Icon, IconButton, Tooltip, tokens } from "@adasupport/byron";
import React from "react";

import { Flex, FlexColumn, FlexV, useReadOnly } from "components/Common";
import { useFilterOptionsDict } from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsReportFilter/hooks";
import {
  DEFAULT_RULE_GROUP,
  type RULE_DSL_VALUE,
  type RuleCondition,
  type RuleDefinition,
  type RuleGroup,
  isRuleGroup,
} from "services/rules";
import { type Variable, useVariables } from "services/variables";

import { RuleConditionComponent } from "./RuleConditionComponent";
import { RuleGroupOperator } from "./RuleGroupOperator";
import { getDefaultRightArgumentValue } from "./helpers";
import * as S from "./styles";

export const getUpdatedRuleGroup = <RG extends RuleGroup>(
  ruleGroup: RG,
  rule: RuleCondition,
  newRule: RuleCondition,
): RG => {
  let newGroupArgs = ruleGroup.args;

  newGroupArgs = newGroupArgs.map((r) => (r === rule ? newRule : r));

  return {
    ...ruleGroup,
    args: newGroupArgs,
  };
};

export const RuleGroupComponent = <RG extends RuleGroup>({
  ruleGroup,
  onChange,
  variables,
  highlightInvalidFields,
  predefinedValues = {},
  isDisabled,
}: {
  ruleGroup: RG;
  onChange: (ruleGroup: RG) => void;
  variables: Variable[];
  highlightInvalidFields?: boolean;
  predefinedValues: {
    [key: string]: { value: string; label: string }[] | undefined;
  };
  isDisabled: boolean;
}) => {
  const handleAddRuleToGroup = (previousRule: RuleCondition, index: number) => {
    const leftOperand = previousRule.args[0];
    const leftOperandVariableType = leftOperand.id
      ? variables.find((v) => v._id === leftOperand.id)?._type
      : undefined;
    const operator = previousRule.name;
    const rightOperand = getDefaultRightArgumentValue(
      leftOperandVariableType,
      operator,
    );

    onChange({
      ...ruleGroup,
      args: [
        ...ruleGroup.args.slice(0, index + 1),
        {
          name: operator,
          args: [leftOperand, rightOperand],
        },
        ...ruleGroup.args.slice(index + 1),
      ],
    });
  };

  const handleGroupOperatorChange = (value: RULE_DSL_VALUE) => {
    onChange({
      ...ruleGroup,
      name: value,
    });
  };

  return (
    <FlexV gap>
      {ruleGroup.args.map((rule, index) => {
        if (isRuleGroup(rule)) {
          return (
            <React.Fragment key={index}>
              <Flex justifyContent="flex-start">
                <RuleGroupOperator
                  ruleGroup={ruleGroup}
                  index={index}
                  onChange={handleGroupOperatorChange}
                />
              </Flex>
              <S.RuleGroupContainer
                gap
                data-testid={`rule-group-${index}`}
                hasMultipleRules={rule.args.length > 1}
              >
                <FlexColumn grow={1} shrink={1}>
                  <RuleGroupComponent
                    ruleGroup={rule}
                    onChange={(value) => {
                      if (value.args.length === 0) {
                        onChange({
                          ...ruleGroup,
                          args: ruleGroup.args.filter((r) => r !== rule),
                        });

                        return;
                      }

                      onChange({
                        ...ruleGroup,
                        args: ruleGroup.args.map((r) =>
                          r === rule ? value : r,
                        ),
                      });
                    }}
                    highlightInvalidFields={highlightInvalidFields}
                    variables={variables}
                    predefinedValues={predefinedValues}
                    isDisabled={isDisabled}
                  />
                </FlexColumn>
              </S.RuleGroupContainer>
            </React.Fragment>
          );
        }

        const previousRule = ruleGroup.args[ruleGroup.args.indexOf(rule) - 1];

        return (
          <React.Fragment key={index}>
            {previousRule && (
              <Flex justifyContent="flex-start">
                <RuleGroupOperator
                  ruleGroup={ruleGroup}
                  index={ruleGroup.args.indexOf(rule)}
                  onChange={handleGroupOperatorChange}
                />
              </Flex>
            )}
            <Flex gap data-testid={`rule-condition-${index}`}>
              <RuleConditionComponent
                rule={rule}
                onChange={(value) => {
                  onChange(getUpdatedRuleGroup(ruleGroup, rule, value));
                }}
                variables={variables}
                highlightInvalidFields={highlightInvalidFields}
                predefinedValues={predefinedValues}
                isDisabled={isDisabled}
              />
              <FlexColumn>
                <Tooltip text="Add a rule to this group">
                  <IconButton
                    dataTestId="add-rule-button"
                    variant="tertiary"
                    aria-label="Add"
                    icon={Icon.Add}
                    isDisabled={isDisabled}
                    onClick={() => handleAddRuleToGroup(rule, index)}
                    iconColor={tokens.colors.text.muted}
                  />
                </Tooltip>
              </FlexColumn>
              <FlexColumn>
                <IconButton
                  isDisabled={isDisabled}
                  variant="tertiary"
                  aria-label="Remove"
                  icon={Icon.Trash}
                  onClick={() => {
                    onChange({
                      ...ruleGroup,
                      args: ruleGroup.args.filter((r) => r !== rule),
                    });
                  }}
                  iconColor={tokens.colors.text.muted}
                />
              </FlexColumn>
            </Flex>
          </React.Fragment>
        );
      })}
    </FlexV>
  );
};

export const RulesetEditor = ({
  ruleset,
  onChange,
  highlightInvalidFields,
}: {
  ruleset: RuleDefinition;
  onChange: (ruleset: RuleDefinition) => void;
  highlightInvalidFields?: boolean;
}) => {
  const { isReadOnly } = useReadOnly();
  const { variables } = useVariables();
  const { browser, channel, csatscore, device, language } =
    useFilterOptionsDict();

  // Rules support only a subset of variables
  const availableVariables =
    variables?.filter(
      (variable) =>
        variable.name === "channel" || // TODO: Remove this once "channel" is marked as meta on the backend
        variable.name === "chatter_id" || // TODO: Remove this once "chatter_id" is marked as meta on the backend
        variable.name === "end_user_id" || // TODO: Remove this once "end_user_id" is marked as meta on the backend
        (variable.scope !== "client_secret" &&
          variable.scope !== "oauth" &&
          variable.scope !== "local" &&
          variable.scope !== "builder_ab_tests" &&
          variable.scope !== "auto_capture"),
    ) || [];

  // Ruleset editor can only have a rule group at the root (ANY or ALL)
  if (!isRuleGroup(ruleset)) {
    return <>Unable to display the ruleset.</>;
  }

  const handleAddRuleGroup = () => {
    onChange({
      ...ruleset,
      args: [...ruleset.args, DEFAULT_RULE_GROUP],
    });
  };

  return (
    <S.RulesetEditorContainer>
      <RuleGroupComponent
        ruleGroup={ruleset}
        onChange={(arg) => {
          if (isReadOnly) return;

          onChange(arg);
        }}
        variables={availableVariables}
        highlightInvalidFields={highlightInvalidFields}
        predefinedValues={{ browser, channel, csatscore, device, language }}
        isDisabled={isReadOnly}
      />
      <S.AddRuleGroupButtonContainer>
        <Button
          dataTestId="add-rule-group-button"
          text="Add"
          icon={Icon.Add}
          variant="secondary"
          isDisabled={isReadOnly}
          onClick={handleAddRuleGroup}
          size="small"
        />
      </S.AddRuleGroupButtonContainer>
    </S.RulesetEditorContainer>
  );
};
