import Immutable from "immutable";
import React from "react";
import { useSelector } from "react-redux";

import { HighlightStringFromLocations } from "components/Common/HighlightString";
import SvgIcon from "components/Common/SvgIcon";
import { Tag } from "components/Common/Tag";
import { type Modality } from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { isTopTag } from "services/tags";

import * as S from "./styles";

interface ResponseSearchResultMatchItem {
  body: string;
  highlights: Record<string, number>[];
}

// This does not fully describe the object, especially the source response attributes
export interface ResponseSearchResultItem {
  blocks: unknown;
  // content is a list of matches. description and handle are a single match (or null)
  content: ResponseSearchResultMatchItem[];
  description: ResponseSearchResultMatchItem | null;
  handle: ResponseSearchResultMatchItem | null;
  tags: unknown;
  _id: string;
  _source: {
    handle: string;
    description: string;
    descriptive_string: string;
    reserved: boolean;
    tags: string[];
    reserved_for: Modality | null;
  };
}

interface Props {
  responseSearchResult: ResponseSearchResultItem;
  onClick: (responseId: string) => void;
}

const ICON_HEIGHT = 20;

/* It's easier (and much much cleaner) to have this forked from the ResponseListItem than to have
 * the two methods of rendering (using objects with a completely different set of properties)
 * squeezed into one component.
 */
export function ResponseSearchResult({ responseSearchResult, onClick }: Props) {
  // eslint-disable-next-line no-underscore-dangle
  const sourceResponse = responseSearchResult._source;

  const variables = useSelector((state) => state.variables);
  const tags = useSelector((state) => state.tags);
  const filteredTags = tags.filter((tag) =>
    sourceResponse.tags.includes(tag.id),
  );

  function renderTitle() {
    const titleMatches = responseSearchResult.handle;

    if (titleMatches) {
      return (
        <HighlightStringFromLocations
          string={titleMatches.body}
          // It's annoying, but the component needs it in immutable form
          locations={Immutable.List(
            titleMatches.highlights.map((h) => Immutable.Map(h)),
          )}
          variables={variables}
        />
      );
    }

    return sourceResponse.handle;
  }

  function renderDescription() {
    const descriptionMatches = responseSearchResult.handle;
    const contentMatches = responseSearchResult.content;

    if (descriptionMatches) {
      return (
        <HighlightStringFromLocations
          string={descriptionMatches.body}
          // It's annoying, but the component needs it in immutable form
          locations={Immutable.List(
            descriptionMatches.highlights.map((h) => Immutable.Map(h)),
          )}
          variables={variables}
        />
      );
    }

    if (contentMatches.length) {
      return contentMatches.map((match, index) => (
        <HighlightStringFromLocations
          key={index}
          string={match.body}
          // It's annoying, but the component needs it in immutable form
          locations={Immutable.List(
            match.highlights.map((h) => Immutable.Map(h)),
          )}
          variables={variables}
        />
      ));
    }

    return sourceResponse.descriptive_string;
  }

  return (
    <S.Container
      onClick={(e) => {
        e.stopPropagation(); // otherwise we also register a click on the opening trigger
        onClick(responseSearchResult._id);
      }}
    >
      <S.Title>
        {renderTitle()}
        <S.IconBar>
          {sourceResponse.reserved && (
            <SvgIcon icon="Lock" height={ICON_HEIGHT} />
          )}
        </S.IconBar>
      </S.Title>
      <S.Description>{renderDescription()}</S.Description>
      <S.Tags>
        {filteredTags.map((tag) => (
          <S.Tag key={tag.id}>
            <Tag tag={tag} isSmall hasIcon={isTopTag(tag.name)} />
          </S.Tag>
        ))}
      </S.Tags>
    </S.Container>
  );
}
