import { useClient } from "services/client";

import { useComponentDidMount } from "./useComponentDidMount";

export enum HUBSPOT_EVENTS {
  TRIAL_BOT_URL = "pe3275991_trial_bot_url_v3",
  TRIAL_ACCOUNT_ACTIVE = "pe3275991_trial_account_active",
  NAME_AGENT = "pe3275991_name_agent",
  SCRAPE_WEB = "pe3275991_scrape_web_v2",
  WRITE_ARTICLE = "pe3275991_write_article",
  INVITE_USER = "pe3275991_invite_user",
  ADD_GUIDANCE = "pe3275991_add_guidance_v2",
  ADD_KNOWLEDGE = "pe3275991_add_knowledge_v2",
  ADD_PROCESS = "pe3275991_add_process",
  ADD_ACTION = "pe3275991_add_action",
  IDENTIFY = "identify",
}

/**
 * Hook for sending Hubspot events
 * NOTE - These functions and events are all disabled unless the client is a free trial bot
 */
export const useHubspotEvents = () => {
  const { isFreeTrial } = useClient();

  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
  const _hsq = (window._hsq = window._hsq || []);

  const sendHubspotEvent = (
    eventName: HUBSPOT_EVENTS,
    properties: object = {},
  ) => {
    if (!isFreeTrial) {
      return;
    }

    let eventType = "trackCustomBehavioralEvent";
    let eventDetails: object = { name: eventName, properties };

    if (eventName === HUBSPOT_EVENTS.IDENTIFY) {
      eventType = HUBSPOT_EVENTS.IDENTIFY;
      eventDetails = properties;
    }

    _hsq.push([eventType, eventDetails]);
  };

  useComponentDidMount(() => {
    if (!isFreeTrial) {
      return;
    }

    const hubspotScriptLoader = "hs-script-loader";
    let script = document.getElementById(hubspotScriptLoader) as
      | HTMLScriptElement
      | undefined;

    if (!script) {
      script = document.createElement("script");
      script.id = hubspotScriptLoader;
      script.src = "//js.hs-scripts.com/3275991.js";
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);
    }
  });

  return {
    sendHubspotEvent,
  };
};
