import Immutable from "immutable";

import { RequestState } from "services/requestState";
import { camelCaseToSnakeCase } from "services/strings";

const defaultState = Immutable.Map({
  byId: Immutable.Map(),
  requests: Immutable.Map(),
  payload: Immutable.Map(),
});

/**
 * @param {Immutable.Map} state
 * @param {Object} action
 * @param {String} action.requestKey
 * @param {Promise} action.pendingRequest
 * @returns {Immutable.Map}
 */
function setRequest(state, action) {
  const existingRequest = state.getIn(["requests", action.requestKey]);

  return state.setIn(
    ["requests", action.requestKey],
    new RequestState({
      result: existingRequest ? existingRequest.result : undefined,
      pendingRequest: action.pendingRequest,
    }),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} action
 * @param {String} action.requestKey
 * @param {Object} action.response
 * @param {Object} ResourceModel
 * @returns {Immutable.Map}
 */
function setFetchOneRequestResult(state, action, ResourceModel) {
  const { data: responseData, meta: responseMeta } =
    ResourceModel.resolveFetchOneResult(action.response.data);

  return state.updateIn(["requests", action.requestKey], (request) =>
    request.merge({
      isResolved: true,
      result: responseData._id,
      pendingRequest: null,
      meta: responseMeta,
    }),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} action
 * @param {String} action.requestKey
 * @param {Object} action.response
 * @param {Object} ResourceModel
 * @returns {Immutable.Map}
 * @throws {Error}
 */
function setFetchManyRequestResult(state, action, ResourceModel) {
  const { data: responseData, meta: responseMeta } =
    ResourceModel.resolveFetchManyResult(action.response.data);

  if ("data" in responseData && responseData.data === "Processing Query...") {
    // Async query processing, nothing to update
    return state.updateIn(["requests", action.requestKey], (request) =>
      request.merge({
        isResolved: false,
        result: null,
        pendingRequest: true,
        meta: responseMeta,
      }),
    );
  }

  if (!Array.isArray(responseData)) {
    throw new Error(`Response data is ${responseData}, expected array`);
  }

  return state.updateIn(["requests", action.requestKey], (request) =>
    request.merge({
      isResolved: true,
      result: responseData.map((attrs) => attrs._id),
      pendingRequest: null,
      meta: responseMeta,
    }),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} action
 * @param {String} action.requestKey
 * @param {String} action.response
 * @returns {Immutable.Map}
 */
function setFetchRequestFailure(state, action) {
  return state.updateIn(["requests", action.requestKey], (request) =>
    request.merge({
      isResolved: false,
      isFailed: true,
      pendingRequest: null,
      errorCode: action.response.status,
      errorData: action.response.data,
    }),
  );
}

/**
 * @param {Immutable.Map} state
 * @param {*} ResourceModel
 * @param {{data: Object[]}} responseData
 * @returns {Immutable.Map}
 */
function pushResources(state, ResourceModel, responseData) {
  const { data } = responseData;

  if ("data" in data && data.data === "Processing Query...") {
    // Async query processing, nothing to update
    return state;
  }

  return state.updateIn(["byId"], (byId) => {
    let newById = byId;

    data.forEach((attributes) => {
      const id = attributes._id;

      /** @type {ResourceRecord} */
      const existingRecord = state.getIn(["byId", id]);

      const newRecord = existingRecord
        ? existingRecord.merge(ResourceModel.deserialize(attributes))
        : new ResourceModel(ResourceModel.deserialize(attributes));

      newById = newById.set(id, newRecord);
    });

    return newById;
  });
}

/**
 * @param {Immutable.Map} state
 * @param {*} ResourceModel
 * @param {Object} responseData
 * @returns {Immutable.Map}
 */
function pushResource(state, ResourceModel, responseData) {
  const { data } = responseData;

  if (!data) {
    return state;
  }

  const id = data._id;
  let newState = state;

  /** @type {ResourceRecord} */
  const existingRecord = state.getIn(["byId", id]);

  // If there's record in the store already - update it
  if (existingRecord) {
    newState = newState.setIn(
      ["byId", id],
      existingRecord.merge(ResourceModel.deserialize(data)),
    );
  } else {
    // If not - create new one from data
    newState = newState.setIn(
      ["byId", id],
      new ResourceModel(ResourceModel.deserialize(data)),
    );
  }

  // Update "all" request result, if exists
  newState = newState.set(
    "requests",
    state.get("requests").mapEntries(([key, value]) => {
      const result = value.get("result");

      if (key === "all" && result && !result.includes(id)) {
        return [key, value.set("result", result.push(id))];
      }

      return [key, value];
    }),
  );

  return newState;
}

/**
 * @param {Immutable.Map} state
 * @param {Object} action
 * @param {String} action.id
 * @param {ResourceRecord} action.newResourceRecord
 * @returns {Immutable.Map}
 */
function updateResource(state, action) {
  const { id, newResourceRecord } = action;

  return state.setIn(["byId", id], newResourceRecord);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} action
 * @param {String} action.id
 * @returns {Immutable.Map}
 */
function deleteResource(state, action) {
  const { id } = action;

  let newState = state.deleteIn(["byId", id]);

  newState = newState.set(
    "requests",
    state.get("requests").mapEntries(([key, value]) => {
      let newResult = value.get("result");

      if (Immutable.List.isList(newResult)) {
        newResult = newResult.filter((resourceId) => resourceId !== id);
      } else if (newResult === id) {
        newResult = null;
      }

      return [key, value.set("result", newResult)];
    }),
  );

  return newState;
}

/**
 * @param {Immutable.Map} state
 * @param {Object} responseData
 * @returns {Immutable.Map}
 */
function setFetchManyRequestPayload(state, action) {
  const { payload } = action;

  if (!payload) {
    return state;
  }

  return state.set("payload", payload);
}

/**
 * @param {Object} ResourceModel
 * @returns {Function}
 */
export default function (ResourceModel) {
  const resourceType = ResourceModel.type;
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  return (state = defaultState, action) => {
    switch (action.type) {
      case `CREATE_ONE_${RESOURCE_TYPE}_REQUEST`: {
        return setRequest(state, action);
      }

      case `CREATE_ONE_${RESOURCE_TYPE}_SUCCESS`: {
        const responseData = ResourceModel.resolveCreateOneResult(
          action.response.data,
        );
        const newState = state.updateIn(
          ["requests", action.requestKey],
          (request) =>
            request.merge({
              isResolved: true,
              result: responseData.data._id,
            }),
        );

        return pushResource(newState, ResourceModel, responseData);
      }

      case `CREATE_ONE_${RESOURCE_TYPE}_FAILURE`: {
        return state.updateIn(["requests", action.requestKey], (request) =>
          request.merge({
            isResolved: false,
            isFailed: true,
          }),
        );
      }

      case `FETCH_ONE_${RESOURCE_TYPE}_REQUEST`: {
        return setRequest(state, action);
      }

      case `FETCH_ONE_${RESOURCE_TYPE}_SUCCESS`: {
        const newState = setFetchOneRequestResult(state, action, ResourceModel);

        return pushResource(
          newState,
          ResourceModel,
          ResourceModel.resolveFetchOneResult(action.response.data),
        );
      }

      case `FETCH_ONE_${RESOURCE_TYPE}_FAILURE`: {
        return setFetchRequestFailure(state, action);
      }

      case `FETCH_MANY_${RESOURCE_TYPE}_REQUEST`: {
        return setRequest(state, action);
      }

      case `FETCH_MANY_${RESOURCE_TYPE}_SUCCESS`: {
        const newState = setFetchManyRequestResult(
          state,
          action,
          ResourceModel,
        );
        const payloadState = setFetchManyRequestPayload(
          newState,
          ResourceModel.resolveFetchManyResult(action.response.data),
        );

        return pushResources(
          payloadState,
          ResourceModel,
          ResourceModel.resolveFetchManyResult(action.response.data),
        );
      }

      case `FETCH_MANY_${RESOURCE_TYPE}_FAILURE`: {
        return setFetchRequestFailure(state, action);
      }

      case `UPDATE_${RESOURCE_TYPE}_ATTRIBUTES`: {
        return updateResource(state, action);
      }

      case `SAVE_ONE_${RESOURCE_TYPE}_REQUEST`: {
        return state.setIn(["requests", "patchOne"], new RequestState());
      }

      case `SAVE_ONE_${RESOURCE_TYPE}_SUCCESS`: {
        const newState = state.updateIn(["requests", "patchOne"], (request) =>
          request.set("isResolved", true),
        );

        return pushResource(
          newState,
          ResourceModel,
          ResourceModel.resolveSaveOneResult(action.response.data),
        );
      }

      case `SAVE_ONE_${RESOURCE_TYPE}_FAILURE`: {
        return state.updateIn(["requests", "patchOne"], (request) =>
          request.merge({
            isResolved: false,
            isFailed: true,
          }),
        );
      }

      case `DELETE_ONE_${RESOURCE_TYPE}_REQUEST`: {
        return state.setIn(["requests", action.requestKey], new RequestState());
      }

      case `DELETE_ONE_${RESOURCE_TYPE}_SUCCESS`: {
        const newState = state.updateIn(
          ["requests", action.requestKey],
          (request) => request.set("isResolved", true),
        );

        return deleteResource(newState, action);
      }

      case `DELETE_ONE_${RESOURCE_TYPE}_FAILURE`: {
        return state.updateIn(["requests", action.requestKey], (request) =>
          request.merge({
            isResolved: false,
            isFailed: true,
          }),
        );
      }

      case `POST_ACTION_${RESOURCE_TYPE}_REQUEST`: {
        return state.setIn(["requests", action.requestKey], new RequestState());
      }

      case `POST_ACTION_${RESOURCE_TYPE}_SUCCESS`: {
        const newState = state.updateIn(
          ["requests", action.requestKey],
          (request) => request.set("isResolved", true),
        );

        return pushResource(
          newState,
          ResourceModel,
          ResourceModel.resolvePostActionResult(action.response.data),
        );
      }

      case `POST_ACTION_${RESOURCE_TYPE}_FAILURE`: {
        return state.updateIn(["requests", action.requestKey], (request) =>
          request.merge({
            isResolved: false,
            isFailed: true,
          }),
        );
      }

      case "PUSH_RESOURCES": {
        if (!action.payload.included) {
          return state;
        }

        return pushResources(state, ResourceModel, {
          data: action.payload.included.filter(
            (resource) => resource._type === resourceType,
          ),
        });
      }

      default:
        return state;
    }
  };
}
