import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchMany } from "actions/resources";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { type Language } from "resourceModels";
import { getResources } from "selectors/resources";
import { adaAPI } from "services/api";
import { useClient } from "services/client";
import { type LanguageCode, type TranslationObject } from "services/language";

export const getVoiceLanguages = async (): Promise<[string[], boolean]> => {
  try {
    const resp = await adaAPI.request({
      method: "GET",
      url: "/voice/languages/supported_languages",
    });

    let languages: string[] = [];

    if (resp.data instanceof Array) {
      languages = resp.data.map((languageCode) =>
        String(languageCode.language_code),
      );
      // remove duplicates
      languages = Array.from(new Set(languages));
    }

    return [languages, true];
  } catch (e) {
    return [[], false];
  }
};

export function useLanguage() {
  const dispatch = useDispatch();
  const allLanguages = useSelector((state) => getResources(state, "language"));
  const [voiceLanguages, setVoiceLanguages] = useState<string[]>([]);
  const { client } = useClient();

  const defaultLanguageCode = client.language;
  const fetchAllLanguages = useCallback(() => {
    dispatch(fetchMany("language"));
  }, [dispatch]);

  useComponentDidMount(() => {
    const getAndSetVoiceLanguages = async () => {
      const [allVoiceLanguages, success] = await getVoiceLanguages();

      if (!success) {
        setVoiceLanguages([]);
      } else {
        setVoiceLanguages(allVoiceLanguages);
      }
    };

    if (client.features.experiment_voice) {
      getAndSetVoiceLanguages();
    }
  });

  // ensures that languages are loaded
  // if the user lands on a page that
  // depends on languages and for some
  // reason, languages are not in state
  useEffect(() => {
    if (!allLanguages) {
      fetchAllLanguages();
    }
  }, [fetchAllLanguages, allLanguages]);

  const clientLanguage = allLanguages?.find(
    (lang) => lang.code === client.language,
  );

  const allLanguagesByCode = allLanguages
    ? allLanguages.reduce(
        (accum, language) => ({ ...accum, [language.code]: language }),
        {} as Record<LanguageCode, Language>,
      )
    : ({} as Partial<Record<LanguageCode, Language>>);

  return {
    allLanguages,
    allLanguagesByCode,
    fetchAllLanguages,
    clientLanguage,
    getAvailableLanguages: useCallback(
      (enabledLanguages: string[]) =>
        allLanguages?.filter(
          (language) =>
            language.code !== client.language &&
            !enabledLanguages.includes(language.code) &&
            client.translatedLanguages.includes(language.code),
        ),
      [allLanguages, client.language, client.translatedLanguages],
    ),
    voiceLanguages,

    // Take translation map and adds missing keys
    normalizeTranslationMap: useCallback(
      (
        translationMap: TranslationObject | null,
        activeLanguageCode?: LanguageCode,
        defaultValue = "",
      ): TranslationObject => {
        const result: TranslationObject = translationMap || {};

        return {
          ...result,
          [activeLanguageCode || defaultLanguageCode]:
            result[activeLanguageCode || defaultLanguageCode] || "",
          [defaultLanguageCode]:
            result[defaultLanguageCode] || defaultValue || "",
        };
      },
      [defaultLanguageCode],
    ),
  };
}
